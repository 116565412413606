import React, { useState, useEffect, Suspense } from 'react';
import Navbar from './components/Navbar';
import NewsFeed from './components/NewsFeed';
import CryptoPriceSidebar from './components/CryptoPriceSidebar';
import TickerTapeWidget from './components/TickerTapeWidget';
import HeatMapWidget from './components/HeatMapWidget';
import TagFilterBar from './components/TagFilterBar';
import './App.css';
import loadConfig from './config/config';
import { saveArticlesToCache, getCachedArticles, isCacheValid } from './utils/localStorageUtils';
import { useLocation } from 'react-router-dom';

// Lazy-load ArticleDetails and NewsAccordion components
const ArticleDetails = React.lazy(() => import('./components/ArticleDetails'));
const NewsAccordion = React.lazy(() => import('./components/NewsAccordion'));

function App() {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [selectedArticleData, setSelectedArticleData] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [loadingAccordion, setLoadingAccordion] = useState(isMobile);
  const [loading, setLoading] = useState(true);
  const [cacheTime, setCacheTime] = useState(null);
  const [slugMap, setSlugMap] = useState({});
  const [config, setConfig] = useState(null);  
  const [slug, setSlug] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); 
  const [expandedIndex, setExpandedIndex] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const newSlug = pathParts[pathParts.length - 1];
    if (newSlug) {
      setSlug(newSlug);
    }
  }, [location]);

  // Load config and fetch articles
  useEffect(() => {
    const loadConfigAndArticles = async () => {
      try {
        const loadedConfig = await loadConfig();
        setConfig(loadedConfig);
        setCacheTime(loadedConfig?.CACHE_EXPIRATION || 900000);// Default 15 mins

        const { cachedArticles, cachedTimestamp } = getCachedArticles();

        if (cachedArticles && isCacheValid(cachedTimestamp, cacheTime)) {
          setArticles(cachedArticles);
          setFilteredArticles(cachedArticles);
          setLoading(false);
        } else {
          const apiKey = loadedConfig.API_KEY;
          const response = await fetch(loadedConfig.API_URLS.NEWS, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-API-Key': apiKey,
            },
          });

          if (!response.ok) {
            throw new Error(`Error fetching articles: ${response.statusText}`);
          }

          const data = await response.json();
          const articlesWithTagsAndSlugs = data.map(article => ({
            ...article,
            tags: article.tags || [],
            slug: article.slug || "",
          }));

          saveArticlesToCache(articlesWithTagsAndSlugs);
          setArticles(articlesWithTagsAndSlugs);
          setFilteredArticles(articlesWithTagsAndSlugs);
          setLoading(false);
          if (isMobile) {
            setLoadingAccordion(false);
          }
        }
      } catch (error) {
        console.error('Error fetching articles:', error);
        setLoading(false);
        setLoadingAccordion(false);
      }
    };

    loadConfigAndArticles();
  }, [isMobile, cacheTime]);

  // Update the slug hashmap whenever articles are set
  useEffect(() => {
    const map = {};
    articles.forEach(article => {
      map[article.slug] = article;
    });

    if (JSON.stringify(slugMap) !== JSON.stringify(map)) {
      setSlugMap(map);
    }
  }, [articles, slugMap]);

  // Function to filter articles based on search query
  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = articles.filter(article =>
      article.title.toLowerCase().includes(lowerCaseQuery) ||
      article.description.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredArticles(filtered);
  }, [searchQuery, articles]);

  const onSelectTag = (tag) => {
    setSelectedTags(prevTags => prevTags.includes(tag)
      ? prevTags
      : [...prevTags, tag]
    );
  };

  const onRemoveTag = (tag) => {
    setSelectedTags(prevTags => prevTags.filter(t => t !== tag));
  };

  const clearAllTags = () => {
    setSelectedTags([]);
  };

  useEffect(() => {
    if (selectedTags.length > 0) {
      const filtered = articles.filter(article =>
        article.tags.some(tag => selectedTags.includes(tag))
      );
      setFilteredArticles(filtered);
    } else {
      setFilteredArticles(articles);
    }
  }, [selectedTags, articles]);

  const showLatestNews = () => {
    const now = new Date();

    const last24Hours = articles.filter(article => {
      const articleDate = new Date(Date.parse(article.pubDate));
      const hoursDifference = (now - articleDate) / (1000 * 60 * 60);
      return hoursDifference < 24;
    });
    
    setFilteredArticles(last24Hours);
  };

  const showAllNews = () => {
    setFilteredArticles(articles);
  };

  const handleArticleSelection = (slug) => {
    const article = slugMap[slug];

    if (article && (!selectedArticleData || selectedArticleData.article !== article)) {
      setSelectedArticleData({ article });
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const closeAllAccordions = () => {
    setExpandedIndex(null); // Close all accordion items
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Do not render anything until config is loaded
  if (!config) {
    return <div>Loading...</div>;
  }

  return (
    <div className="app-grid" data-testid="app-grid">
      {loadingAccordion && isMobile && (
        <div className="loading-spinner" data-testid="loading-spinner-mobile">
          <div className="circle-spinner"></div>
          <img src="/images/loading-icon.webp" alt="Logo" className="loading-logo" />
        </div>
      )}

      {!isMobile && (
        <aside className="app-sidebar-left" data-testid="crypto-sidebar">
          <CryptoPriceSidebar closeAllAccordions={closeAllAccordions} onSearch={handleSearch} />
        </aside>
      )}

      <main className="app-main-content" data-testid="main-content">
        <Navbar
          onShowAllNews={showAllNews}  // Use the showAllNews function
          onShowLatestNews={showLatestNews}  // Use the showLatestNews function
          onSearch={handleSearch}  // Pass the handleSearch function to Navbar
          isMobile={isMobile}
          closeAllAccordions={closeAllAccordions}  // Close accordions on brand click
        />

        {selectedTags.length > 0 && (
          <TagFilterBar
            selectedTags={selectedTags}
            onRemoveTag={onRemoveTag}
            clearAllTags={clearAllTags}
          />
        )}

        {!isMobile ? (
        <div className="content-wrapper" data-testid="content-wrapper">
            {loading && (
              <div className="loading-spinner-desktop" data-testid="loading-spinner-desktop">
                <div className="circle-spinner"></div>
                <img src="/images/loading-icon.webp" alt="Logo" className="loading-logo" />
              </div>
            )}
            <div className="news-feed-wrapper" data-testid="news-feed-wrapper">
              <NewsFeed
                articles={filteredArticles}
                onSelectArticle={setSelectedArticleData}
                selectedArticle={selectedArticleData?.article}
                onSelectTag={onSelectTag}
                onSelectSlug={handleArticleSelection}
                config={config}  // Pass config to NewsFeed
              />
            </div>
            <div className="article-details-wrapper" data-testid="article-details-wrapper">
              <Suspense fallback={<div>Loading article details...</div>}>
                {selectedArticleData && selectedArticleData.article ? (
                  <ArticleDetails 
                    article={selectedArticleData.article}
                    thumbnail={selectedArticleData.article.imageUrl || null}
                    onSelectTag={onSelectTag}
                    config={config}  // Pass config to ArticleDetails
                  />
                ) : (
                  <div>
                    {/* Text Header */}
                    <div className="widget-header">
                      <strong>
                        CamelBits كل ما تحتاجه عن العملات الرقمية في مكان واحد
                      </strong>
                      <p>
                        منصة CamelBits توفر لك الأخبار الدقيقة والمحدثة عن العملات الرقمية عبر تجميع البيانات من المصادر الموثوقة حول العالم.
                        <br />
                        يرجى اختيار مقال لعرض التفاصيل
                      </p>
                    </div>
                    {/* HeatMapWidget */}
                    <HeatMapWidget />
                  </div>
                )}
              </Suspense>
            </div>

        </div>
        ) : (
          <Suspense fallback={<div>Loading news...</div>}>
            <NewsAccordion 
              articles={filteredArticles}
              slug={slug}  // Pass slug to NewsAccordion
              data-testid="news-accordion"
              onFilterByTag={onSelectTag}
              searchQuery={searchQuery}  // Inserted searchQuery prop here
              onSearch={handleSearch}    // Inserted onSearch prop here
              expandedIndex={expandedIndex}  // Control expanded accordion
              setExpandedIndex={setExpandedIndex}  // Set expanded accordion
            />
          </Suspense>
        )}
      </main>

      <footer className="app-footer" data-testid="app-footer">
        <TickerTapeWidget />
      </footer>
    </div>
  );
}

export default App;
